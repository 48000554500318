/*! Grid based on https://github.com/necolas/griddle by Nicolas Gallagher (@necolas) */

// establish base font size in config so that media queries can be resized if the base context chagnes
body { font-size: em($base-font-size, 16px); }

// wrapper defaults to 100% fluid with side gutters
// when triggering a breakpoint wrapper becomes fixed width and centered
.wrapper {
    margin-left: auto;
    margin-right: auto;
    padding-left: em($grid-gutter);
    padding-right: em($grid-gutter);
}

// for responsive images
img { max-width: 100%; }
.no-js .responsive-image { display: none; }

// Grid container
// Must only contain `.grid` or `.grid-cell` components as children.
.grid {
    display: block;
    padding: 0;
    margin: 0 -0.5 * em($grid-gutter);
    // Ensure consistent default alignment
    text-align: $grid-direction;
    // Remove inter-unit whitespace for all non-monospace font-families
    // If you're using a monospace base font, you will need to set the `grid`
    // font-family to `sans-serif` and then redeclare the monospace font on
    // the `grid-cell` objects.
    letter-spacing: -0.31em;
    // Protect against WebKit bug with optimizelegibility
    text-rendering: optimizespeed;
}

.opera:-o-prefocus,
.grid {
    word-spacing: -0.43em;
}

// Child `grid` object adjustments
// Used for more complex fixed-fluid hybrid grids
.grid > .grid {
    overflow: hidden;
    margin-right: 0;
    margin-left: 0;
}


// Grid units
// No explicit width by default
.grid__cell {
    width: 100%;

    display: -moz-inline-stack;
    display: inline-block;
    *vertical-align: auto;
    zoom: 1;
    *display: inline;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    margin: 0;
    padding: 0 0.5 * em($grid-gutter);

    /* controls vertical positioning of units */
    vertical-align: top;

    /* keeps unit content correctly aligned */
    text-align: $grid-direction;

    /* reset text defaults */
    letter-spacing: normal;
    word-spacing: normal;
    text-rendering: auto;
}

// Modifier: horizontally center all grid units
.grid--center {
    text-align: center;
}

// Modifier: horizontally center one unit
.grid__cell--center {
    display: block;
    margin: 0 auto;
}