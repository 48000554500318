.style {
  background: #efefef;

  header {
    background: $brand-primary;
    padding: 15px 0;

    .logo {
      text-align: center;

      @include breakpoint(768px) {
        @include span(4 of 12);
        text-align: left;
      }

      img {
        max-width: 100%
      }
    }/* end logo style */

    .version {

      p {
        margin-bottom: 0;
        color: $header-text-color;
        text-align: right;
      }
    }/* end version styles */

  }/* end header styles */


  .main {
    padding: 60px 0;

    .section-heading {
      color: $dark-gray;
      font-weight: 400;
    }
  }

  .half {
    @include breakpoint(768px) {
      @include span(6 of 12);
    }
  }

  .swatch {
    display: inline-block;
    border: 4px solid #fff;
    box-shadow: 0 0 7px #333;
  }

  .swatchgrid{
    list-style-type:none;
    margin-bottom: 40px;

    li {
      padding: 8%;
    }
  }

  .swatchgrid.color {

    li {
      width: 19%;
    }

    .color-swatch-1 {
      background: $brand-primary;
    }

    .color-swatch-2 {
      background: $brand-secondary;
    }

    .color-swatch-3 {
      background: $light-gray;
    }

    .color-swatch-4 {
      background: $gray;
    }

    .color-swatch-5 {
      background: $dark-gray;
    }
  }

  .swatchgrid.texture {

    li {
      width: 32%;
      padding: 15%;
    }
  }
}

.buttonGroup {
  margin-top: 20px;
}

a.typography-sample {
  display:block;
  margin-bottom: 30px;
}

.element {
  margin-bottom: 10px;
}