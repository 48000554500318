.page-interior .page-sidebar ul.sub-navigation.board-member-nav {
	li {
		padding: 0;
		a {
			font-family: interval_sans_prolight;
			font-size: 16px;
			color: rgb(50, 46, 47);
		}
		&.current-menu-item {
			a {
				color: #0987d5;
			}
			ul.sub-menu {
				li a {
					color: rgb(50, 46, 47);
				}
			}
		}
		 
	}
	.sub-menu {
		position: relative;

		opacity: 1;
		max-height: 100%;
		width: 100%;
		top: 0;
		li {
			padding-left: 10px; 
		}
	}
	#menu-item-275 {
		display: none;
	}
}

.minutes-of-prior-meetings, .board-meetings {
	.page-interior .page-sidebar ul.sub-navigation.board-member-nav {
		#menu-item-275 {
			display: block;
		}
	}
}