// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

.gform_wrapper {
	.gfield_label {
		font-weight: 200 !important;
		margin: 0 !important;
	}
	.ginput_container {
		margin: 0 !important;

		input, textarea {
			border: 1px solid #d9d9d9;
		}
		input {
			height: 55px;
		}
		textarea {
			height: 140px !important;
		}
	}
	.textarea.large {
		max-height: 180px !important;
	}
	.gform_footer {
		padding: 0 !important;
		margin: 0 !important;
	}
	input[type='submit'] {
	    padding: 10px 20px !important;
	    color: #fff;
	    // float: right;
	    // margin-right: 15px;
	    background-color: #0987d5 !important;
	    margin: 20px 0 !important;
	    font-size: 18px !important;
		border: none !important;

	    &:hover, &:focus {
	    	text-decoration: none;
  			background-color: #c99900 !important;
	    }
	}
}

.grecaptcha-badge {
	z-index: 900;
}
  
@media only screen and (max-width: 380px) {
	.ginput_recaptcha {
		transform: scale(0.77);
		transform-origin: 0 0;

		iframe {
			height: 78px !important;
		}
	}
}