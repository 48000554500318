.banner {
  position: relative;
  width: 100%;
  overflow: auto;

  font-size: 18px;
  line-height: 24px;
  text-align: center;

  color: rgba(255, 255, 255, .6);
  text-shadow: 0 0 1px rgba(0, 0, 0, .05), 0 1px 2px rgba(0, 0, 0, .3);

  background: #5b4d3d;

  p {
    text-align: center;
    color: white;
    @include breakpoint(992px) {
      text-align: left;
    }
  }

  a {
    color: white;
    
  }
 
  ul {
    list-style: none;
    width: 300%;

    li {
      display: block;
      float: left;
      width: 33%;
      height: 452px;

      -o-background-size: 100% 100%;
      -ms-background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
    }

  }

  .inner {
    padding: 160px 0 110px;
  }

  h1 {
    font-size: 40px;
    line-height: 52px;
    color: #fff;

    @include breakpoint(992px) {
      text-align: left;
      font-size: 80px;
      font-weight: bold;
      line-height: 75px;
      text-transform: uppercase;
    }
  }

  h2 {
    font-size: 40px;
    line-height: 52px;
    color: #fff;
    text-transform: uppercase;
    font-family: "interval_sans_probold";

    @include breakpoint(992px) {
      text-align: left;
      font-size: 5em;
      line-height: 77px;
      letter-spacing: normal;
    }
  }

  .btn {
    display: inline-block;
    margin: 25px 0 0;
    padding: 9px 22px 7px;
    clear: both;

    color: #fff;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;

    border: 2px solid rgba(255, 255, 255, .4);
    border-radius: 5px;

    -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, .3));
    -moz-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, .3));
    -ms-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, .3));
    -o-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, .3));
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, .3));

    &:hover {
      background: rgba(255, 255, 255, .05);
    }
  }

  .btn:active {
    -webkit-filter: drop-shadow(0 -1px 2px rgba(0, 0, 0, .5));
    -moz-filter: drop-shadow(0 -1px 2px rgba(0, 0, 0, .5));
    -ms-filter: drop-shadow(0 -1px 2px rgba(0, 0, 0, .5));
    -o-filter: drop-shadow(0 -1px 2px rgba(0, 0, 0, .5));
    filter: drop-shadow(0 -1px 2px rgba(0, 0, 0, .5));
  }

  .dot {
    -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, .3));
    -moz-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, .3));
    -ms-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, .3));
    -o-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, .3));
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, .3));
  }

  .dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    width: 100%;
    li {
      display: inline-block;
      *display: inline;
      zoom: 1;

      width: 10px;
      height: 10px;
      line-height: 10px;
      margin: 0 4px;

      text-indent: -999em;
      *text-indent: 0;

      border: 2px solid #fff;
      border-radius: 6px;

      cursor: pointer;
      opacity: .4;

      -webkit-transition: background .5s, opacity .5s;
      -moz-transition: background .5s, opacity .5s;
      transition: background .5s, opacity .5s;
    }
    li.active {
      background: #fff;
      opacity: 1;
    }
  }

  .arrow {
    display: inline;
    padding-left: 10px;
    cursor: pointer;
  }

  .arrows {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: #fff;
  }

  .slide-1 {
    background-color: rgb(76, 156, 218);
  }

  .slide-2 {
    background-color: rgb(56, 160, 56);
  }

  .slide-3 {
    background-color: rgb(119, 125, 127);
  }

  .slide-4 {
    background-color: rgb(105, 157, 212);
  }

  .slide {
    .slide-inner {
      @include span(isolate 8 at 3);
      padding-top: 80px;
      @include breakpoint(480px) {
        padding-top: 120px;
      }
      @include breakpoint(992px) {
        
        width: 960px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        float: none;
      }

      .slide-left {
        display: none;
        @include breakpoint(992px) {
          display: block;
          @include span(4 of 12);
          img {
            max-width: 100%;
          }
        }
      }

      .slide-right {
        @include breakpoint(992px) {
          @include span(8 of 12);
        }

        a {
          position: relative;
          text-decoration: none;

          @include breakpoint(992px) {
            &::after {
              position: absolute;
              bottom: -10%;
              height: 2px;
              width: 0;
              content: "";
              left: 0;
              right: 0;
              background: white;
              transition: width 0.55s ease;
            }

            &:hover,
            &:focus {
              &::after {
                width: 100%;
                transition: width 0.5s ease;
              }
            }
          }
        }
      }
    }
  }
}

.mainNav {
  .menu-item-has-children.current-menu-item a, .page-item-13.current-menu-item a, .current-menu-parent a {
    padding-bottom: 10px;
    background: url('../images/nav-active.png') repeat-x 0 bottom;
  }
   ul.sub-menu {

    li {
      padding: 5px; 
      a {
        font-size: 16px;
        font-family: interval_sans_prolight;
      }
    }
  }
}

.announcement {
  height: 40px;
}

#modal {
  overflow: scroll;
  background-color: rgba(black, 0.5);
  z-index: 4000;

  .modal-title {
    color: $brand-primary;
    font-family: $heading-font;
  }

  .modal-dialog {
    @include breakpoint(768px) {
      max-width: 75%;
    }

    @include breakpoint(1600px) {
      max-width: 50%;
    }
  }

  .btn-secondary {
    background-color: $brand-primary;
    color: white;
    border-radius: 0;

    &:hover {
      background-color: darken($brand-primary, 10%);
    }
  }
}