.copyright {
	.copy {
		text-align: center;
		@include breakpoint(992px) {
			div {
				&:first-of-type {
					float: left;
				}
				&:last-of-type {
					float: right;
				}
			}
		}

		a:hover {
			text-decoration: none;
			color: white;
		}
	}
}

.footer-contents {
	margin-top: 20px;

	.footer-info {
		@include span(9.5 of 12);
		padding-right: 20px;
    	border-right: 1px solid #a2a2a2;
	}

	.footer-social {
		@include span(2.5 of 12);
		.social-links {
			width: 50px;
			float: right;
			height: 50px;
			cursor: pointer;
			background: url(../images/social.png) no-repeat center;
		}
	}
}
