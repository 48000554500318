.strapline {
    .CSPE-notice {
        padding-bottom: 30px;
        ul {
          margin: 0 0 30px 30px;
        }
        &:last-of-type {
          padding-bottom: 0;
        }
        h2 {
          font-size: 30px;
          font-weight: 400;
          text-align: left;
          font-family: interval_sans_prolight,Helvetica,sans-serif;
          margin-bottom: 20px;
        }
        p {
            margin-bottom: 30px;
        }
    }
    p {
        line-height: 30px;
    }
}

.home {
  .strapline {
    padding: 40px 0;
  }
}


.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
    margin: inherit 10px;
}

.video-container iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}

.interior-hero  {
    .hero-inner {
        .cta-left {
            @include breakpoint(992px) {
                h1 {
                    font-size: 74px;
                }
            }
             @include breakpoint(1200px) {
                h1 {
                    font-size: 80px;
                }
            }
        }
    }
}
.state-board-member .interior-hero .hero-inner .cta-left {
    @include breakpoint(992px) {
        @include span(8 of 12);
    }
}

.about-us.candidate {
    .interior-hero {
        background-color: #055180;
    }
}

.cta-left {
    padding-top: 20px;
}

.about-us .cta-left {
    padding-top: 0;
}

.about-us:not(.candidate) {
    .cta-left {
        padding-top: 40px;
    }
    .cta-right {
        img {
            right: 120px;
        }
    }
}

.page-interior .page-sidebar .sub-navigation {
    .current-menu-item {
        a {
            color: #0987d5;
        }
    }
}

.breadcrumbs {
    padding-bottom: 30px;
    font-family: interval_sans_prolight;
    font-size: 14px;
    margin: 15px 10px;
    a {
        color: #322e2f;
        text-decoration: underline;
    }
}

.tools-downloads {
    .breadcrumbs {
        margin: 20px 15px;
    }
}

.candidate {
    .breadcrumbs {
        margin: 10px;
    }
}

.page:not(.home) .strapline {
    padding-top: 0;
}

.what-to-expect {
    margin: 0 0 60px;
    border-top: 1px solid #a2a2a2;
    padding: 60px 20px 0;
    h3 {
        font-family: 'interval_sans_prolight';
        font-size:  58px;
        color: #0987d5;
        line-height: 65px;
        margin: 0 0 20px;
    }
    .video-container {
        @include breakpoint(992px) {
            padding: 60px 0 0;
            max-width: 55%;
            padding-bottom: 23.9%;
        }

    }
}

.video-library {
    .video-container {
        @include breakpoint(992px) {
            max-width: 70%;
            padding-bottom: 38%;
        }

    }
}

.icon {
    height: 63px;
    display: block;
}

.talk-us p {
    line-height: 30px;
}

.contact-info {
     @include breakpoint(992px) {
            @include span(9 of 12);
            @include push(3);
        }
}

.front-who {
    padding: 0 25px;
    @include breakpoint(992px) {
        padding: 0;
    }
}
table {
    border-collapse: collapse;
    padding: 0;
    margin: 0 0 11px 0;
    width: 100%;
}

table th {
    text-align: left;
    border-bottom: 2px solid #eee;
    vertical-align: bottom;
    padding: 0 10px 10px 10px;
    text-align: right;
}

table td {
    vertical-align: top;
    padding: 10px;
    text-align: left;
}

table th:nth-child(1),
table td:nth-child(1) {
    text-align: left;
    padding-left: 0;
    font-weight: bold;
}
thead th {
    font-family: interval_sans_probold;
}
/* responsive table */
@media screen and (max-width: 768px) {
    table,
    tbody {
        display: block;
        width: 100%;
        border: none !important;
    }

    thead { display: none; }

    table tr,
    table th,
    table td {
        display: block;
        padding: 0;
        text-align: left;
        white-space: normal;
    }

    table tr {
        border-bottom: 1px solid #eee;
        padding-bottom: 11px;
        margin-bottom: 11px;
    }

    table th[data-title]:before,
    table td[data-title]:before {
        content: attr(data-title) ":\00A0";
        font-weight: bold;
    }

    table td {
        border: none !important;
        margin-bottom: 6px;
        color: #444;
    }

    table td:empty { display: none; }

    table td:first-child {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 6px;
        color: #333;
    }
    .table-bordered tr td:first-of-type {
        text-align: left;
    }

    table td:first-child:before { content: ''; }

    .data-title {
        float: left;
        font-weight: 700;
        &:after {
            content: '\00a0';
        }
    }
}

#mc_embed_signup {
    .mc-title {
        text-align: left;
        @include breakpoint(560px) {
            @include span(4.5 of 12);

        }
        h4 {
            font-size: 25px;
            text-align: center;
                @include breakpoint(992px) {
                    margin: 10px 0;
                    font-size: 28px;
                }
            }
        }

    #mc_embed_signup_scroll {
        margin: 0;
         @include breakpoint(560px) {
            @include span(7.5 of 12);
        }
        .mailing-left {
            width: 91.5%;
            float: left;
            margin: 0;
            input {
                width: 100%;
                height: 55px;
                background-color: rgba(0,0,0,0.1);
                border: none;
            }
        }
        .mailing-right {
            width: 8.5%;
            float: left;
            margin: 0;
            input {
                margin: 0;
                padding: 0;
                width: 100%;
                background: #c99900 url(../images/arrow-signup.png) no-repeat center center;
            }
        }
    }
}

.faqs .page-main {
    h2 {
        line-height: 33.6px;
    }
    ol, ul {
        li.has-submenu {
            margin-bottom: 0;
        }
    }
    ol {
        li {
            ol {
                li {
                    margin-left: 40px;
                }
            }
        }
    }
    .bordered-header {
        margin-bottom: 8px;
    }
    .sub-header {
        font-size: 18.6667px;
        line-height: 30px;
    }
    .bold-import {
        font-size: 18.6667px;
    }
    ul.hyphenated {

        li {
            list-style-type: none;
            margin-left: 0;
            &:before {

                margin-right: 19px;
                content: " - ";
            }

        }
        ul.circle-dot {
            li {
                // list-style-type: circle;
                margin-left: 60px;
                &:before {
                     font-family: 'Courier New';
                font-size: 16px;
                    content: " o ";
                }
            }
        }
    }
}

.exam-schedule .page-main {
    h2 {
        margin-bottom: 25px;
    }
}

.exam-cost .page-main h3 {
    margin-bottom: 10px;
}

.exam-credibility .page-main {
    h2 {
        line-height: 33.6px;
    }
    h4 {
        font-family: interval_sans_prolight;
        font-size: 30px;
    }
    .together {
        margin: 30px 0;
         h5 {
            margin: 10px 0;
        }
    }
    p.small-text {
        font-family: Arial, sans-serif;
        font-size: 13.3333px;
    }
    h5 {
        font-size: 16px;
        font-family: interval_sans_probold;
        margin: 30px 0;
    }
    ul {
        margin-bottom: 0;
        margin-top: 5px !important;
         li {
            line-height: 30px;
        }
    }
}

.login {
    .interior-hero {
        background-color: #999;
    }
    .page-main {
        @include push(3);
    }
    .form-label {
        font-family: Verdana, sans-serif;
        font-size: 16px;
        font-weight: normal;
        display: block;
        p {
            margin: 0;
        }
        input {
            width: 105px;
            height: 20px;
        }
    }
    #submit {
        margin: 10px 0;
        color: #FFFFFF;
        font-size: 22px;
        display: block;
        height: 55px;
        padding: 0 10px;
        border: 0;
        overflow: hidden;
        background: #c99900;
        cursor: pointer;
        &:hover, &:focus {
            background-color: #0987D5;
        }
    }
}

.board-member {
    .interior-hero {
         background-color: rgb(119, 125, 127);
    }
    .cta-right {
        img {
            right: 4%;
        }
    }
    .board-pdf:before {
        content: '';
        padding: 8px;
        background: url(../images/application-pdf.png) no-repeat center;
    }
}

.bulletins-registration {
    .page-main {
        li a {
            line-height: 30px;
        }
    }
}

.candidate {
    h2 {
            font-family: 'interval_sans_prolight';
            font-size: 58px;
            color: #0987d5;
            line-height: 65px;
            margin: 0 0 20px;
    }
    .what-to-expect {
        margin-top: 40px;
    }
}

.mailchimp {
    padding-top: 20px;
}

body.error404 {
  .fourofourcontainer {
    padding-top: 120px;
    background-color: #4c9cda;
  }
  .fourofourinterior {
    background-color: #fff;
    padding-top: 60px;
  }
}

.phone {
    &:hover {
        text-decoration: none;
    }

    &.contact {
        color: #0987d5;
    }
}

iframe {
    border: 0;
}

.contact-page {
    .contact-header {
        text-align: center;
    }

    .container {
      padding: 0;
    }

    .contact-form {
      background-color: $card-gray;
      padding: 10px;
      height: 100%;
      margin-bottom: 35px;
  
      .gform_wrapper .gform_footer input[type=submit] {
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.5;
        background-color: $brand-primary;
        color: white;
        padding: 10px 20px;
  
        @include breakpoint(768px) {
          margin-left: 10px;
        }
  
        @include breakpoint(992px) {
          font-size: 16px;
        }
  
        @include breakpoint(1440px) {
          font-size: 19px;
        }
  
        &:hover {
          background-color: darken($brand-primary, 9%);
        }
      }
  
      .gform_footer {
        padding: 16px 9px 10px;
  
        @include breakpoint(768px) {
          padding: 16px 0 10px;
        }
      }
  
      .gform_wrapper {
        margin-top: 0;
      }
  
      .gform_wrapper ul.gform_fields {
        label {
          font-size: 18px;
          font-family: $heading-font;
          font-weight: 900;
        }
  
        li {
          color: $brand-primary;
          font-size: 12px;
  
          @include breakpoint(768px) {
            font-size: 16px;
          }
        }
  
        .ginput_container {
          input {
            width: 100%;
          }
        }
      }
  
      .ginput_recaptcha > div {
        margin-top: -12px;
  
        @include breakpoint(768px) {
          margin-top: -25px;
        }
      }
  
      .ginput_container_select {
        select {
          padding: 8px 0 8px 3px;
  
          @include breakpoint(768px) {
            padding: 6px 0 6px 3px;
          }
        }
      }
    }
  
    .contact-card {
      background-color: $card-gray;
      padding: 20px;
      height: 100%;
  
      iframe {
        border: 5px solid $brand-primary;
        margin-top: 20px;
        height: 350px;
        width: 100%;
      }
  
      .phone {
        color: black;
  
        &:hover {
          color: black;
        }
      }
  
      h2 {
        font-size: 28px;
        margin-bottom: 10px;
      }
  
      p {
        font-weight: 500;
      }
  
      span {
        font-size: 15px;
        font-weight: 500;
  
        @include breakpoint(992px) {
          font-size: 16px;
        }
      }
    }
  
    .contact-content {
      background-color: $brand-primary;
      padding: 20px;
      height: 100%;
    }
  }

.announcement-table {
    display: none;

    @include breakpoint(768px) {
        display: block;
    }
}

.announcement-table-mobile {
    display: block;

    @include breakpoint(768px) {
        display: none;
    }
}