html * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body {
    font-family: $body-font;
    background: #fff;
    margin: 0;
    padding: 0;
    font-size: $font-size;
    line-height: $line-height;
}

html, body {
  overflow-x: hidden;
}

.row {
   margin: 0;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0 0 $heading-margin-bottom 0;
    font-weight: $heading-font-weight;
    font-family: $heading-font;
}

p {
    margin: 0 0 $paragraph-margin-bottom 0;
}

strong {
    background: none;
    padding: 0;
    font-weight: bold;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}

.clr {
	clear: both;
}

.full {
	width: 100%;
}

menu, ul, ol {
	margin: 0;
	padding: 0;
}

em {
  font-style: italic;
}

.btn {
  background: $btn-color;
  display:inline-block;
  padding: 10px 20px;
  color: $btn-text-color;
  text-decoration:none;

  &:hover {
    background: darken($btn-color, 10%);
    text-decoration:none;
  }
}

.btn-secondary {
  background: $btn-secondary-color;
  display:inline-block;
  padding: 10px 20px;
  color: $btn-secondary-text-color;
  text-decoration:none;

  &:hover {
    background: darken($btn-secondary-color, 10%);
    text-decoration: none;
  }
}

.buttonGroup {
  border: 1px solid $btn-group-border-color;
  @include clearfix;
  float: left;
  border-radius: 4px;
  .btn {
    float: left;
    border-right: 1px solid $btn-group-border-color;

    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;
      border-right: none;
    }
  }
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: $heading-color;
}

h1, .h1 {
  font-size: $h1-size;
}

h2, .h2 {
  font-size: $h2-size;
}

h3, .h3 {
  font-size: $h3-size;
}

h4, .h4 {
  font-size: $h4-size;
}

h5, .h5 {
  font-size: $h5-size;
}

h6, .h6 {
  font-size: $h6-size;
}

a {
  color: $link-color;
}

input[type="text"], input[type="password"], textarea {
  width: 100%;
  border: 1px solid #ccc;
  padding: 4px 0 4px 10px;

  &:focus {
    box-shadow: 0 0 3px $brand-primary;
    outline:none;
  }
}

.container {

  @include container;
  padding: 0 10px;

  @include breakpoint(768px) {
    @include container(750px);
  }

  @include breakpoint(992px) {
    @include container(970px);
  }

  @include breakpoint(1200px) {
    @include container(1170px);
  }
}

h1 {
  font-size: 44px;
}

@font-face {
  font-family: 'interval_sans_prolight';
  src: url('../fonts/interval_light-webfont.eot');
  src: url('../fonts/interval_light-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/interval_light-webfont.woff') format('woff'), url(../fonts/interval_light-webfont.ttf) format('truetype'), url(../fonts/interval_light-webfont.svg#interval_sans_prolight) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'interval_sans_probold';
  src: url(../fonts/interval_bold-webfont.eot);
  src: url(../fonts/interval_bold-webfont.eot?#iefix) format('embedded-opentype'), url(../fonts/interval_bold-webfont.woff) format('woff'), url(../fonts/interval_bold-webfont.ttf) format('truetype'), url(../fonts/interval_bold-webfont.svg#interval_sans_probold) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'interval_sans_proregular';
  src: url(../fonts/interval_regular-webfont.eot);
  src: url(../fonts/interval_regular-webfont.eot?#iefix) format('embedded-opentype'), url(../fonts/interval_regular-webfont.woff) format('woff'), url(../fonts/interval_regular-webfont.ttf) format('truetype'), url(../fonts/interval_regular-webfont.svg#interval_sans_proregular) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'interval_sans_prosemibold';
  src: url(../fonts/interval_semi_bold-webfont.eot);
  src: url(../fonts/interval_semi_bold-webfont.eot?#iefix) format('embedded-opentype'), url(../fonts/interval_semi_bold-webfont.woff) format('woff'), url(../fonts/interval_semi_bold-webfont.ttf) format('truetype'), url(../fonts/interval_semi_bold-webfont.svg#interval_sans_prosemibold) format('svg');
  font-weight: normal;
  font-style: normal;
}

header {

  .logo {
    img {
      position: relative;
      bottom: 10px;
    }
  }

  position: absolute;
  z-index: 3000;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;

  .container {
    position: relative;
  }

  .logo-container {
    @include span(5 of 12);
    a {
      text-decoration: none;
    }
  }

  .mobileMenu > a > span {
    font-size: 45px;
    color: white;
    padding-top: 6px;
  }

  .mobileMenu {
    @include breakpoint(992px) {
      display: none;
    }

    a {
      float: right;
      color: #2d4496;
      font-size: 28px;
      margin-top: -15px;
      @include breakpoint(480px) {
        margin-top: -5px;
      }
      @include breakpoint(560px) {
        margin-top: 0;
        span {
          font-size: 50px;
        }
      }
      @include breakpoint(560px) {
        margin-top: 10px;
      }
    }

    // Open mobilemenu ....
    &.open {
      @include breakpoint(992px) {
        display: none;
      }
    }
  }

  menu {
    @include span(10 of 12);
    .menu {
      margin-bottom: 0;
      float: right;
      li {
        float: left;
        a {
          font-size: 16px;
          font-weight: 200;
          color: white;
          &:hover {
            color: purple;
          }
        }
        span {
          font-size: 16px;
          color: black;
          font-weight: 200;
        }
        &:active {
          // TODO: Active state
        }
      }
    }
  }

  nav {
    @include span(10 of 12);
    .nav {
      float: right;
      li {
        float: left;
        a {
          font-size: 22px;
          font-weight: 200;
          color: black;
          &:hover {
            color: purple;
          }
        }
      }
    }
  }

  .menuContainer {
    position: absolute;
    text-align: right;
    top: 72px;
    display: none;
    padding-top: 5px;
    right: 10px;
    height: 250px;
    width: 220px;
    z-index: 999;
    transition: all .4s ease;
    background: #fff;
    opacity: 0;
    box-shadow: 0px 0px 5px #aaa;
    @include breakpoint(992px) {
      display: none;
    }
    &:after {
      bottom: 100%;
      left: 185px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
      border-width: 10px;
    }
    &.open {
      display: block;
      padding-top: 5px;
      position: absolute;
      top: 72px;
      right: 10px;
      height: 250px;
      width: 220px;
      z-index: 999;
      transition: all .4s ease;
      background: #fff;
      opacity: 1;
      @include breakpoint(992px) {
        display: none;
      }
    }
    li {
      display: block;
      width: 100%;
      a, span {
        padding: 10px 15px;
        display: block;
        color: #336699;
        text-decoration: none;
      }
    }
  }

}

.mainNav, .topMenu {
  display: none;
  @include breakpoint(992px) {
    display: block;
  }
}

.logo {
  @include span(4 of 12);

  @include breakpoint(992px) {
    @include span(2 of 12);
  }

  img {
    max-width: 100%;
  }
}

nav {
  @include breakpoint(992px) {
    @include span(10 of 12);
  }
}

header .logo {
  @include breakpoint(992px) {
    @include span(3 of 12);
  }
}

header {
  nav {
    @include breakpoint(992px) {
      @include span(9 of 12);
    }
  }
}

.topMenu li {
  position: relative;
  float: right;
  display: inline-block;
  padding: 10px 10px 20px;
  color: #000000;
  font-family: "interval_sans_prolight";
  font-size: 14px;
  a {
    color: white;
  }
  span {
    color: white;
  }
}

.mainNav {
  > li {
    a {
      color: white;
    }
  }
  li {
    margin: 0;
    list-style-type: none;
    position: relative;
    float: right;
    display: inline-block;
    padding: 10px 15px;
    text-transform: uppercase;
    a {
      text-decoration: none;
      font-size: 18px;
      &:hover {
        color: #000000;
      }
    }
    &:hover {
      background-color: white;
      box-shadow: -10px 10px 1px rgba(0, 0, 0, .2);
      ul {
        max-height: 500px;
        padding: 10px 0 15px 0;
        transition:  opacity 0.75s, max-height 1.5s;
        transition-delay: .2s;
        opacity: 1;
        box-shadow: -10px 10px 1px rgba(0, 0, 0, .2);
        li:hover {
          box-shadow: none;
        }
      }
      > a {
        color: #0987d5;
      }
    }
  }
}

ul.sub-menu {
  // transition: max-height 0s ease-out;
  // transition-delay: .2s;
  float: none;
  opacity: 0;
  background: #ffffff;
  // height: auto;
  position: absolute;
  // left: -9000px;
  left: 0;
  top: 39px;
  height: auto;
  max-height: 0;
  width: 200px;
  overflow: hidden;

  li {
    float: none;
    color: #000000;
    padding: 10px;

    a {
      color: black;
      padding: 5px 10px;
      white-space: nowrap;
      font-size: 14px;
      text-transform: none !important;
      &:hover {
        color: #0987d5;
      }
    }
  }
}

.submenu-double ul.sub-menu {
  width: 400px;
  li {
    float: left;
    width: 49%;
  }
}

td {
  padding: 30px;
}

// Front page

.strapline {
  background: url('../images/front-strap-background.jpg') no-repeat center;
  background-size: cover;
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;

  line-height: 35px;

  .check-container {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    @include breakpoint(1200px) {
      display: block;
    }
  }

}

.triple-feature {
  padding-top: 60px;
  padding-bottom: 60px;

  h1 {
    color: #0987D5;
    font-size: 58px;
    font-weight: lighter;
  }

  .feature {
    position: relative;
    h3 {
      font-weight: 300;
    }
    @include breakpoint(992px) {
      @include span(4 of 12)
    }
    text-align: center;
    background-color: #f1f1f1;
    padding: 40px 0;
    li {
      text-align: center;
      padding: 12px 0;
      list-style: none;
      a {
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
}

.icon-banner {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 24px;
  text-transform: uppercase;
  i {
    padding-bottom: 25px;
  }
}

.faqs .page-main ul li {
  list-style-type: square;
  list-style-position: inside;
  padding: 5px;
}

.faqs .page-main ul li > ul li {
  list-style-type: circle;
  padding-left: 20px;

}

.faqs .page-main ul li > ul li > ul li {
  list-style-type: disc;
  padding-left: 30px;
}

.faqs .page-main ol li {
  list-style-type: decimal;
  list-style-position: inside;
  padding: 5px;
}

.faqs .page-main ol li > ol li {
  list-style-type: lower-latin;
  padding-left: 20px;
}

.faqs .page-main ol li > ol li > ol li {
  list-style-type: lower-roman;
  padding-left: 30px;
}

.information {
  @include breakpoint(992px) {
    @include span(6 of 12);
  }
}

.form {
  @include breakpoint(992px) {
    @include span(6 of 12);
  }
}

.icon-banner {
  background-color: #055180;
}

.banner-icon {
  @include breakpoint(640px) {
    @include span(6 of 12)
  }

  @include breakpoint(992px) {
    @include span(3 of 12)
  }
  padding: 50px 0;
  text-align: center;
  background-color: #055180;
  color: #ffffff;
  font-weight: bold;
}

.banner-icon a {
  text-decoration: none;
  color: #ffffff;
}

.banner-icon a:hover {
  color: #C99900;
}

footer {
  padding: 30px 0;
}

.footerSeal {
  text-align: center;
  img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    display: block;
  }

  @include breakpoint(992px) {
    @include span(2 of 12);
    text-align: left;
    img {
      margin-bottom: 0;
    }
  }
}

.iframe-container {
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-width: 700px;
}

.footerText {
  line-height: 25px;
  @include breakpoint(992px) {
    @include span(10 of 12);
  }
}

.info-btn {
  ul {
    text-align: center;
  }
  li {
    position: relative;
    display: inline-block;
    text-align: center;
    font-family: Verdana, sans-serif;
    font-size: 20px;
    vertical-align: baseline;
    background-color: #F1F1F1;
    color: black;
    padding: 20px 0 20px;
//    padding: 30px 0 30px;
    width: 300px;
    margin: 20px auto;
    @include breakpoint(480px) {
      margin: 15px;
  }

    &:hover {
      background-color: #C99900;
      color: #ffffff;
      a {
        color: white;
      }
    }
    a {
      padding: 15px !important;
      color: black;
      text-decoration: none;
      font-family: interval_sans_prolight;
      font-size: 28px;
    }
  }
}

// Video Player
#huge_it_album_video_player_670 .playlist_wrapper,
#huge_it_album_video_player_670 .playlist_wrapper ul {
    background-color: #699dd4 !important;
}
#huge_it_album_video_player_670 .playlist_wrapper ul li.item.active,
#huge_it_album_video_player_670 .playlist_wrapper ul li.item:hover {
    background-color: #3cb23c !important;
}

// Buttons bar

.tools-btn {
  i {
    padding-bottom: 20px;
  }
  h3 {
    font-family: 'interval_sans_prolight';
    text-align: center;
    font-size: 3.625em;
    color: #0987d5;
    line-height: 65px;
    margin: 0 0 20px;
  }
  p {
    padding: 0 20px;
    text-align: center;
    @include breakpoint(992px) {
      text-align: left;
    }
  }
  ul {
    text-align: center;
  }
  li {
    position: relative;
    display: inline-block;
    text-align: center;
    list-style: none;
    font-family: Verdana, sans-serif;
    font-size: 20px;
    vertical-align: baseline;
    background-color: #F1F1F1;
    //    padding: 30px 0 30px; No top-padding, let the <a> fill it.
    width: 300px;
    margin: 20px 0;
    @include breakpoint(480px) {
      margin: 0 15px 40px;

    }
    a {
      display: block;
      text-decoration: none;
      font-family: interval_sans_prolight;
      font-size: 28px;
      color: #000;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    &:hover {
      background-color: #c99900; // Hover state on the correct element
      a {
        color: white; // Hover state cascades white onto <a>s.
      }
    }
  }
}

// Strapline announcement

.announcement {
  background-color: #699dd4;
  color: #ffffff;
  text-align: center;
  line-height: 40px;

  p {
    margin-bottom: 0;
  }

  a {
    color: #ffffff;
  }
}

// Copyright

.copyright {
  background-color: rgba(0, 0, 0, 0.80);
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 12px;
  color: white;
  a {
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .client {
    @include breakpoint(992px) {
      float: right;
    }
  }

  .linksCopy {
    @include breakpoint(992px) {
      float: left;
    }
  }
}

// Interior Pages

.interior-hero {

  .container {
    position: relative;
  }

  position: relative;

  h1 {
    color: white;
    font-size: 48px;
    @include breakpoint(1200px) {
      line-height: 87px;
    }
  }

  a {
    color: white;
  }

  .hero-inner {
    padding-top: 100px;
    height: 260px;
    text-transform: uppercase;

    h1, h2, h3, h4, h5, h6 {
      color: white;
    }

    @include breakpoint(576px) {
      padding-top: 120px;
    }

    @include breakpoint(992px) {
      height: 355px;

    // @include span(isolate 9 of 12);
    }

    h1 {
      font-family: "interval_sans_probold", sans-serif;
      @include breakpoint(992px) {
        font-size: 80px; // previously 80 ... previously 67 ... now 80 again but not gonna fit ...
        font-weight: normal;
      }

    }

    .cta-left {
      text-align: center;
      @include breakpoint(992px) {
        @include span(6 of 12);
        text-align: left;
      }
    }

    .cta-right {
      display: none;
      @include breakpoint(992px) {
        display: block;
        @include span(6 of 12);
      }
      img {
        max-width: 100%;
        position: absolute;
        bottom: 0;
      }
    }
  }
}

// Individual image manipulations for interior hero ...
.tools-downloads .interior-hero .cta-right img {

  @include breakpoint(992px) {
    right: 0%;
  }

  @include breakpoint(1200px) {
    right: 4%;
  }
}

.board-members .interior-hero .cta-right img {

  @include breakpoint(992px) {
    right: 0%;
  }

  @include breakpoint(1200px) {
    right: 4%;
  }

}

.about-us .interior-hero .cta-right img {
  @include breakpoint(992px) {
    right:30px;
  }
}

.about-us .interior-hero h1,
.talk-us .interior-hero h1 {
  @include breakpoint(992px) {
    padding-top: 15px;
  }
}

.about-the-exam .interior-hero .cta-right img {
  @include breakpoint(992px) {
    right: 19%;
  }

  @include breakpoint(1200px) {
    right: 21%;
  }
}

.talk-us .interior-hero .cta-right img {
  @include breakpoint(992px) {
    right: 3%;
  }

  @include breakpoint(1200px) {
    right: 6%;
  }
}
// Individual Interior Pages

.cta-left h1 {
  color: purple;
}

.page-main p {
  line-height: 30px;
}

.page-interior {
  position: relative;
  // padding-top: 60px;
  padding-bottom: 60px;

  .side-icon {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    @include breakpoint(1200px) {
      display: block;
    }
  }

  .page-main {

    color: #322e2f;

    @include breakpoint(992px) {
      @include span(9 of 12);
    }

  }

  .page-sidebar {
    margin-bottom: 40px;
    text-align: center;

    @include breakpoint(992px) {
      margin-left: 0 !important;

      @include span(3 of 12);
      text-align: left;
      margin-bottom: 0;
    }

    .sub-navigation {
      li {
        list-style-type: none;
        a {
          padding: 10px 15px;
          display: block;
          text-decoration: none;
          color: #322e2f;
          font-weight: 100;
          font-family: "interval_sans_prolight", Sans-Serif;
          font-size: 18px;

          &:hover {
            color: #0987d5;
          }
        }
      }

      li.active {
        a {
          color: #0987d5;
        }
      }

    }
  }
}

.minutes-list {
  li {
    a {
      &:before {
        content: url('../images/pdf-icon.png');
        margin-right: 10px;
      }
    }
  }
}

// Individual interior pages

.tools-downloads {
  .interior-hero {
    background-color: rgb(119, 125, 127);
  }
}

.tools-downloads-home {
  .page-interior {
    h1 {
      color: $brand-primary;
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
}

address {
  margin-bottom: 20px;
}

.page-main ul {
  margin-bottom: 20px;
}

.about-us {
  .interior-hero {
    background-color: rgb(9, 135, 213);
  }

  ul.board-members {
    margin-bottom: 20px;
    padding-left: 40px;
  }
}

.about-the-exam {
  .interior-hero {
    background-color: rgb(60,178,60);
  }
}

.talk-us {
  .interior-hero {
    background-color: rgb(255,168,0);
  }
}

.admin-add-property header {
  position: relative;
}

.admin-add-property .form {
  width: 100%;
}

.page-main .btn {
  margin-bottom: 20px;
}

/* Contact styling */

.talk-us input[type="text"] {
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: 16px;
}

.talk-us textarea {
  height: 180px;
  margin-bottom: 20px;
  margin-top: 5px;
  font-size: 16px;
}

.talk-us .btn {
  margin-bottom: 20px;
  font-size: 18px;
  border: none;
}

.educator .btn, .state-board-member .btn, .candidate .btn {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 160px;
  text-align: center;
  margin-bottom: 20px;
}

.about-us.candidate .info-btn,
.about-us.state-board-member .info-btn {
  padding-top: 40px;
  padding-bottom: 40px;
}

.about-us.candidate .info-btn h2,
.about-us.educator .info-btn h2,
.about-us.state-board-member .info-btn h2 {
  text-align: center;
  color: #0987d5;
}

.front-more {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 45px;
  height: 45px;
  text-indent: -99999px;
  overflow: hidden;
  background: url('../images/box-corner.png') no-repeat 0 0;
}

.front-more:hover {
  background-position: 0 -45px
}

.feature a {
  color: black;
  font-weight: bold;
}

.feature a:hover {
  color: #0987D5;
}

.feature a.btn {
  color: white;
}

.login, .sign-up {
  .theContent {
    padding-top: 40px;
    hr {
      display: none;
    }
  }
}

.admin-add-property {
  .backendMain {
    padding: 60px 0;
  }

  .controls > .mce-panel {
    margin-bottom: 20px;
  }

  label {
    display: block;
    margin-bottom: 5px;
  }

  input[type="text"] {
    margin-bottom: 20px;
    font-size: 16px;
  }

}

.users-list, .login, .sign-up, .admin-add-property {
  header {
    position: relative;
    border-bottom: 1px solid #ccc;
    background-color: green;
  }

  .theContent {
  }

  .footer-container {
    border-top: 1px solid #ccc;
  }
}

.btn {
  border: none;
  font-size: 16px;
}

.table {
  margin-bottom: 20px;
}

.table thead th {

  padding-left: 5px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-top: 10px;
  font-weight: 600;
  text-align: left;
  font-size: 14px;
  @include breakpoint(560px) {
    font-size: 18px;
  }
}

.table thead {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
}

.table tbody td {
  border: 1px solid #ccc;
}

iframe {
  width: 100% !important;
  height: 410px;
}

.bulletins-registration .page-main ul li {
  padding: 2px 0x;
  margin-bottom: 10px;
}

.page-main a {
  text-decoration: none;
}

.page-main a:hover {
  text-decoration: underline;
}

.slide-link {
  padding: 0px 50px 0px 5px;
  display: block;
  width: 100%;
}

.topMenu .top-dropdown {
  background-color: rgba(0, 0, 0, 0.1);
  width: 240px;
  position: relative;
  padding-bottom: 10px;
  .arrow {
    background: linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.1)), url(../images/arrow-iama.png) no-repeat top;
    padding: 17px 20px;
    position: absolute;
    bottom: 0;
    right: 0;
  }


  .slide-link {
    font-size: 1.4em;
    text-decoration: none;
    height: 20px;
    margin-top: -5px
  }

  &.open {
    background-color: rgba(0, 0, 0, 0.5);



    ul {
      max-height: 500px;
      padding-bottom: 10px;
      transition:  background-color 1.5s, max-height 1.5s;


    }
    .arrow {
      background: url(../images/arrow-iama.png) no-repeat bottom;

    }
  }

  ul {
    transition: max-height 0.5s;
    height: auto;
    max-height: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 35px;
    float: none;
    z-index: 300;
    li {
      padding: 0;
      display: block;
      width: 100%;
      a {
        font-size: 17.6px;
        font-family: "interval_sans_prolight";
        display: block;
        text-decoration: underline;
        font-weight: normal;
        width: 100%;
        padding: 5px 15px;
        &:hover {
          background-color: #c99900
        }
      }
    }
  }

}

.topMenu {

  .derp-arrow {
    color: #ccc;
    font-size: 22px;
    font-weight: bold;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  @include breakpoint(992px) {
    & > ul {
      & > li:after {
        content: "|";
        color: #fff;
        padding-left: 15px;
      }
      & > li:first-of-type:after {
        display: none;
      }
    }
  }
}

.page-main h1 {
  font-weight: 200;
  margin-bottom: 30px;
}

.page-main h2 {
  font-weight: 200;
  font-size: 29px;
  margin-bottom: 5px;
}

.interior .page-main .btn, .btn {
  background-color: #0987d5;
}

.interior .page-main .btn:hover, .btn:hover, .talk-us .btn:hover  {
  text-decoration: none;
  background-color: #c99900;
}

.table-bordered tr td:first-of-type {
  font-weight: bold;
  text-align: center;
}

.table-bordered thead th {
  text-align: center;
}

.active > a {
  padding-bottom: 10px;
  background: url('../images/nav-active.png') repeat-x 0 bottom;
}

.board-members .page-main ul li {
  padding-bottom: 10px;
}

.page-main ul li {
  margin-left: 60px;
  list-style-type: disc;
}

.video-container {
  margin-bottom: 40px;
}

.text-center {
  text-align: center;
}

.request-scores address p {
  margin-bottom: 0;
}

/* Weird custom stuff to accomodate strange requests, headers that have different styles than other headers, etc.  Standard client crap. */

.helpful-links h4 {
  font-family: "interval_sans_probold", sans-serif;
  font-weight: bold;
  margin-bottom: 5px;
  /* Client wanted to change fonts here, I think.  */
}

.who-nbpme .page-main h5 {
  font-family: "interval_sans_probold", sans-serif;
  font-weight: 600;
  font-size: 15px;
  /* Here too */
}

.faqs .page-main h5,
.faqs .page-main h3 {
  font-family: "interval_sans_probold", sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.board-members .page-main h3 {
  font-family: "interval_sans_probold", sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.exam-credibility h5 {
  font-family: "Verdana", sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.helpful-links address {
  line-height: 30px;
}

.newsroom .page-main ul li {
  margin-bottom: 20px;
}

.who-nbpme .page-main ul li {
  margin-bottom: 10px;
  list-style-type: disc;
}

.faqs .page-main ul li,
.faqs .page-main ol li {
  margin-bottom: 15px;
}

.board-members ul li {
  list-style-type: none;
}

.exam-credibility ul li {
  margin-bottom: 10px;
}

.exam-credibility .page-main ul ul,
.faqs .page-main ul ul,
.faqs .page-main ul ol,
.faqs .page-main ol ol {
  margin-top: 20px;
}

.bluethingbottom small {
  color: white;
  text-transform: none;
  font-size: 16px;

  a {
    color: white;
  }
}

.page-main h2 {
  font-family: "Verdana", sans-serif;
  font-size: 25px;
}

.panel {
  box-shadow: none;
}

#mc_embed_signup_scroll {

  .btn {
    margin-left: auto;
    margin-right: auto;
    border-radius: 0;
    width: 114px;
    display: block;
    @include breakpoint(992px) {
      margin-top: 0;

      width: auto;
    }
  }

  input[type="email"] {
    font-size: 18px;
    width: 100%;
    padding: 7px;
    border: 2px solid #ccc;
  }
}

.about-us h4 {
  font-family: "interval_sans_probold", sans-serif;
  font-weight: 600;
  font-size: 15px;
  /* Here too */
}

.about-us .page-main li {
  list-style-type: none;
  margin-bottom: 13px;
}

header .container,
.interior-hero .container,
.container {
  @include breakpoint(1200px) {
   max-width: 1024px;
  }
}

.field-items p.inner {
  padding-left: 60px;
}

.about-us .page-main li {
  margin-left: 20px;
}

.who-nbpme h4 {
  font-size: 25px;
  font-family: "Verdana", sans-serif;
  font-weight: 400;
  margin-bottom: 5px;
}

.announcement a {
  font-weight: bold;
  font-size: 14px;
}

.announcement a:hover {
  color: black;
}

.about-us.board-members .page-main li {
  margin-bottom: 0;
}

.about-us.board-members h3 {
  margin-bottom: 20px;
}

.about-us.newsroom .page-main ul {
  padding-left: 20px;
}

.about-us.newsroom .page-main li {
  list-style-type: disc;
  line-height: 27px;
  margin-bottom: 5px;
}

.about-us.newsroom h4,
.about-us.newsroom h5 {
  font-family: "Verdana", sans-serif;
  font-weight: normal;
  font-size: 24px;
  margin-bottom: 5px;
}

ul.pdf-ul {
  list-style-image: url('../images/custom-pdf.png');
}

a {
  color: #0987d5;
}

a:hover {
 color: #c99900;
}

.practice-tests .pdf-ul li {
  padding-bottom: 10px;
}

.btn:hover {
  color: white;
}

.video-library h4 {
  font-family: "Verdana", sans-serif;
  font-weight: normal;
}

.about-the-exam .page-main strong {
  font-family: "interval_sans_probold",  sans-serif;
}

.exam-cost .page-main h3,
.part-ii-cspe .page-main h3,
.cspe .page-main h3,
.cspe-exam .page-main h3 {
  font-family: "Verdana", sans-serif;
  font-size: 24px;
}

.cspe-exam .page-main ul li {
  margin-bottom: 20px;
  margin-top: 20px;
}

.interior.cspe-exam .page-main h3 {
  margin-bottom: 10px;
}

/* Different headers, iframe stuff on this page ... */

.part-ii-cspe .page-main h3 {
  margin-bottom: 5px;
}

.part-ii-cspe .page-main iframe {
  margin-bottom: 20px;
}

/* Tables are different on this page, too, things align
 differently .... */

.part-ii-cspe .page-main table {
  text-align: left;
}

.part-ii-cspe .table-bordered tr td:first-of-type {
  text-align: left;
}

.part-ii-cspe .table-bordered tr td {
  padding: 10px; /* 30 is too much for these tables */
}

.part-ii-cspe .table-bordered tr td p,
.part-ii-cspe .table-bordered tr td a {
  font-weight: normal !important;
}

/* Weird differently-sized h5s in here that are smaller than the rest of the text for some reason ... */

.part-ii-cspe .table-bordered tr td h5 {
  font-weight: bold;
  font-size: 13px;
  font-family: "Verdana";
}

/* Reset line height around the table here, too ... */

.part-ii-cspe .table-bordered tr td h5,
.part-ii-cspe .table-bordered tr td a,
.part-ii-cspe .table-bordered tr td p {
  line-height: normal;
}

/* Bordered headers for the FAQ page.... */

.bordered-header {
  font-family: "Verdana";
  font-size: 24px;
  border-top: 1px solid #777777;
  padding-top: 10px;
  padding-bottom: 10px !important;
  border-bottom: 1px solid #777777;

}

h2.bordered-header {
  margin-bottom: 20px;
}


.board-members .announcement p {
  visibility: hidden;
}

.exam-schedule .table thead tr:first-of-type,
.score-release-dates .table thead tr:first-of-type {
  background-color: #ddd;
  font-family: "Whargarbl";
  font-size: 24px;
  font-weight: normal;
  text-align: left;

}

.exam-schedule .table-bordered thead th,
.score-release-dates .table-bordered thead th{
  text-align: left;
  font-weight: normal;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 16px;
  @include breakpoint(480px) {
    font-size: 22px;

  }
}

.exam-schedule .table tr td:first-of-type,
.score-release-dates .table tr td:first-of-type {
  font-size: 22px;
  font-family: 'interval_sans_probold';
}

.table-bordered td {
  padding: 2px !important;
  font-size: 14px;
  @include breakpoint(768px) {
    font-size: 16px;
    padding: 20px !important;
  }
}

.exam-credibility .page-main h2 {
  margin-bottom: 15px;
}

ul.sub-navigation li {
  margin-bottom: 0;
}

.practice-tests .page-main strong {
  font-family: "Verdana";
  font-weight: bold;
  font-size: 14px;
}

.practice-tests .page-main ul li {
  padding-left: 0;
  margin-left: 45px;
}

.helpful-links .page-main h4 {
  font-size: 30px;
}
.btn {
    border-radius: 0;
}
.wrapper {
  padding: 0;
}


#vidplay {
  .player {
    padding: 56.25% 0 0 0;
    position: relative;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
      }
  }

  .playlist {
    display: flex;
    overflow-x: scroll;

    .playlist-item {
      position: relative;
      flex: 1 0 30%;
      max-width: 30%;

      &.playing {
        &::after {
          content: '\f144';
          font-family: FontAwesome;
          background-color: rgba(0, 0, 0, .5);
          width: 100%;
          height: 100%;
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          padding-top: 15px;
          font-size: 60px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}
