$current-bp: 0;
$counter: 0;

// push small breakpoint label into list
$first-breakpoint: append($first-breakpoint, ());

// Javascript hook for current breakpoint label
@media (min-width: 0) {
    body::before { content: breakpoint-list(nth($first-breakpoint, 1), $first-breakpoint, $counter); display: none; }
}
@if ($ie-support) { .lt-ie9 head { font-family: breakpoint-list(nth($first-breakpoint, 1), $first-breakpoint, $counter); } }


// Breakpoint mixin
// =============================================================================

@mixin breakpoint($min: false, $max: false, $label: false, $orientation: false, $wrapper: true, $resolution: false) {

    // set warnings if arguments are invalid
    @if ($min == false and $max == false and $label == false and $orientation == false and $wrapper == true and $resolution == false) {
        @warn "breakpoint requires you to pass some kind of argument. It can't read your mind. :)";
    }
    @if ($min == 0 and $max == false and $label == false and $orientation == false and $wrapper == true and $resolution == false) {
        @warn "If you pass zero as a minimum you need to specify a max column value.";
    }

    // create MQ labels
    @if ($label) {

        // normalize quotes
        $label: quote(unquote($label));

        $counter: $counter + 1;

        // push label to label list names = push(names, myname)
        $first-breakpoint: append($first-breakpoint, $label, comma);

    }

    // set current breakpoint for fluid function
    $current-bp: $min;

    // min width mq
    @if ($min and $max == false) {
        @if ($min > 0) {
            // create min width mq
            // can we remove duplication of code here?
            @if ($orientation) {
                @media ( min-width: (fixed($min) + em($grid-gutter)) * ($base-font-size / 16px) ) and ( orientation: $orientation ) {
                    @include breakpoint-extras($min, $wrapper, $grid-overlay) { @content };
                }
            } @else {
                @media ( min-width: (fixed($min) + em($grid-gutter)) * ($base-font-size / 16px) ) {

                    // label current breakpoint
                    @if($label) { body::before { content: breakpoint-list($label, $first-breakpoint, $counter); } }

                    @include breakpoint-extras($min, $wrapper, $grid-overlay) { @content };
                }
            }
            // ie fallback
            @if ($ie-support and $min <= $ie-support and $orientation == false) {
                .lt-ie9 {
                    @include breakpoint-extras($min, $wrapper, $grid-overlay: false) { @content };

                    // label current breakpoint
                    @if($label) { & head { font-family: breakpoint-list($label, $first-breakpoint, $counter); } }


                }
            }
        }
    }

    // max width mq
    @if ($max) {
        // create max only mq
        @if ($min == 0 or $min == false) {
            @if ($orientation) {
                @media ( max-width: fixed($max) + em($grid-gutter) * ($base-font-size / 16px) ) and ( orientation: $orientation ) { @content }
            } @else {
                @media ( max-width: fixed($max) + em($grid-gutter) * ($base-font-size / 16px) ) { @content }
            }
        // create min and max mq
        } @else {
            @if ($orientation) {
                @media ( min-width: fixed($min) + em($grid-gutter) * ($base-font-size / 16px) ) and ( max-width: fixed($max) + em($grid-gutter) ) and ( orientation: $orientation ) { @content }
            } @else {
                @media ( min-width: fixed($min) + em($grid-gutter) * ($base-font-size / 16px) ) and ( max-width: fixed($max) + em($grid-gutter) ) { @content }
            }
        }
    }

    // orientation only mq
    @if ($min == false and $max == false) {
        @if ($orientation) {
            @media ( orientation: $orientation ) { @content }
        }
    }

    // pixel ratio mq
    @if ($resolution) {
        // warn if invalid value
        @if ($resolution != 'high' and $resolution != 'low') {
            @warn "$resolution only supports values of 'high' or 'low'";
        }
        @if ($resolution == 'high') {
            @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) { @content }
        }
        @if ($resolution == 'low') {
            @media (-webkit-max-device-pixel-ratio: 1.5), (max-resolution: 144dpi) { @content }
        }
        // ie fallback
        @if ($ie-support and $resolution == 'low') {
            .lt-ie9 { @content }
        }
    }
}

// private mixin for generating extras in a min width mq
@mixin breakpoint-extras($min, $wrapper, $grid-overlay) {

    @if($grid-overlay) { @include grid-overlay($min); }

    // proper sizing of wrapper
    @if($wrapper) { .wrapper { width: fixed($min) - em($grid-gutter); } }

    @content

}

// Grid overlay
// =============================================================================

@mixin grid-overlay($col: false) {
    .grid-overlay {
        position: fixed; top: 0; bottom: 0; pointer-events: none; z-index: 10000;

        $overlay-color: transparentize(red, .9);
        background: -webkit-linear-gradient(left, transparent $grid-gutter, $overlay-color $grid-gutter, $overlay-color $grid-gutter + $grid-column);
        background: -moz-linear-gradient(left, transparent $grid-gutter, $overlay-color $grid-gutter, $overlay-color $grid-gutter + $grid-column);
        background: -o-linear-gradient(left, transparent $grid-gutter, $overlay-color $grid-gutter, $overlay-color $grid-gutter + $grid-column);
        background: linear-gradient(left, transparent $grid-gutter, $overlay-color $grid-gutter, $overlay-color $grid-gutter + $grid-column);

        @if ($col) {
            left: 50%;
            width: fixed($col) + em($grid-gutter);
            margin-left: fixed($col) / -2 - (em($grid-gutter)/2);
            -webkit-background-size: $grid-gutter + $grid-column;
            -moz-background-size: $grid-gutter + $grid-column;
            -o-background-size: $grid-gutter + $grid-column;
            background-size: $grid-gutter + $grid-column;
        } @else {
            left: 0;
            right: $grid-gutter;
            -webkit-background-size: 25%;
            -moz-background-size: 25%;
            -o-background-size: 25%;
            background-size: 25%;
        }
    }
}
@if($grid-overlay) { @include grid-overlay; }